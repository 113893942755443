<template>
  <div class='body'>
    <Nav></Nav>
    <div class="banner" style="margin-bottom:12px" >
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content flex-j-b'>
      <left-menu  :activeKey='tab' :data='leftMenuData' title='走进青欣' @change='changeTab'></left-menu>
       <div class='content-box' style='margin-left:32px;' >
          <div v-if='pageData.content' v-html="pageData.content"></div>
        </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
import LeftMenu from '@/components/pageComponents/LeftMenu'
import { design } from '@/api'
export default {
  name: 'CompanyCulture',
  components: {
    Nav,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      tab: '1',
      pageData: {},
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      leftMenuData: [
        {
          title: '集团介绍',
          key: '0',
        },
        {
          title: '集团文化',
          key: '1',
        },
        {
          title: '公司荣誉',
          key: '2',
        },
        {
          title: '资质证书',
          key: '3',
        },
      ],
    }
  },
  activated() {
    this.tab = '1'
  },
  async mounted() {
    await this.getDesignData()
  },
  methods: {
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'companyCulture' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    changeTab(e) {
      this.tab = e
      if(e === '0' && this.$route.path !== '/company_intro') {
        this.$router.push('/company_intro')
      }
      if(e === '1' && this.$route.path !== '/company_culture') {
        this.$router.push('/company_culture')
      }
      if(e === '2' && this.$route.path !== '/company_honor') {
        this.$router.push('/company_honor')
      }
      if(e === '3' && this.$route.path !== '/company_qualification') {
        this.$router.push('/company_qualification')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.page-content {
  padding: 24px 0;
}
.content-box {
  box-sizing: border-box;
  padding: 32px;
  min-height: 600px;
  width: 1008px;
  background: #fff;
  box-shadow: 0 4px 8px #ddd;
  font-size: 16px;
  color: #000;
  border-radius: 8px;
}
</style>